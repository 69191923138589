import React, { Suspense, VFC } from 'react';
import {
  HashRouter as Router, Redirect, Route, Switch,
} from 'react-router-dom';
import { Loader } from 'components/layouts';
import * as routes from 'modules';
import { authContext } from 'contexts';
import * as routesConstants from 'constants/routes';

const routesArray = Object.values(routes);

const Routing: VFC = () => {
  const { isAuthorised, user } = React.useContext(authContext);
  const userRole = user.role;

  const groupedRoutes = routesArray
    .filter(({ roles, isAuthorized: isAuthorisedRoute }) => (
      isAuthorised
        ? (roles as string[]).includes(userRole)
        : isAuthorisedRoute === isAuthorised))
    .map(({
      path, component,
    }) => <Route path={path} key={path} component={component} exact />);

  return (
    <Suspense fallback={<Loader isLoading />}>
      <Router hashType="slash">
        <Switch>
          {groupedRoutes}
          {isAuthorised && <Redirect to={routesConstants.MENU} from="/" />}
          {!isAuthorised && (<Redirect to={routesConstants.LOGIN} from="/" />)}
        </Switch>
      </Router>
    </Suspense>
  );
};

export default Routing;
