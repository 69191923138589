import { LINE_TYPES } from '../modules/orders/Layout/layout.config';

export const recursiveRemove = (list: any[], id: any) => list
  .map((item) => ({ ...item }))
  .filter((item) => {
    if (item.children) {
      item.children = recursiveRemove(item.children, id);
    }
    return item.tempId !== id;
  });

export const recursiveClean = (list: any[]): any[] => list
  .map((item) => ({
    ...item,
    name: item?.lineType === LINE_TYPES.order_line ? null : item?.name,
    children: (Array.isArray(item?.children) && item?.children.length) ? recursiveClean(item.children) : null,
  }));
