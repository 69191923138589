import { env, sessionStore } from 'utils';

const headers = {
  'Content-Type': 'application/json',
};

const request = (url: string, options: { [s: string]: unknown } | null = null) => (fetch(url, {
  ...options,
  headers: {
    ...headers,
    Authorization: `Bearer ${sessionStore.token.getItem()}`,
  },
}).then(async (response) => {
  const contentType = response.headers.get('Content-Type');
  switch (contentType) {
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
      const blob = await response.blob();
      return { blob, response };
    }
    default: return response.json();
  }
}));

const apiGet = (resource: string) => request(`${env.servicetURL}${resource}`,
  { method: 'GET' });

const apiPost = (data: unknown, resource: string) => request(`${env.servicetURL}${resource}`,
  { method: 'POST', body: JSON.stringify(data) });

const apiPatch = (data: unknown, resource: string) => request(`${env.servicetURL}${resource}`,
  { method: 'PATCH', body: JSON.stringify(data) });

const apiDelete = (resource: string) => request(`${env.servicetURL}${resource}`,
  { method: 'DELETE' });

export const api = {
  apiGet,
  apiPost,
  apiPatch,
  apiDelete,
};
