import { lazy } from 'react';
import * as roles from 'constants/roles';
import * as routes from 'constants/routes';

const config = {
  path: routes.SYSTEM_CONFIGURATION,
  roles: [roles.ADMIN],
  isAuthorized: true,
  component: lazy(() => import('./Component')),
};

export default config;
