import { authContext } from 'contexts';
import React from 'react';

import style from './style.module.scss';

interface ICommonNavBarProps{
  className: string,
}

const CommonNavBar = ({ className }: ICommonNavBarProps) => {
  const { onLogout } = React.useContext(authContext);

  return (
    <div className={className}>
      <button className={style.nav_container__btn} onClick={onLogout}>Logout</button>
    </div>
  );
};

export default CommonNavBar;
