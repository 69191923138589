import React, {
  FC, useCallback, useEffect, useReducer,
} from 'react';
import authContext, { context } from 'contexts/auth';
import { api } from 'services/api';
import { sessionStore } from 'utils';
import { Loader } from '../../layouts';

const ACTIONS = {
  LOGIN_START: 'LOGIN_START',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILED: 'LOGIN_FAILED',
  INITIALISING_END: 'INITIALISING_END',
  LOADING_START: 'LOADING_START',
  LOADING_END: 'LOADING_END',
  LOGOUT: 'logout',
  UNATHORISED: 'UNATHORISED',
  ATHORISED: 'ATHORISED',
  LOGIN_ERROR: 'LOGIN_ERROR',
};

const reducer = (state = context, action: { type: string, payload?: any}) => {
  const { type, payload } = action;

  switch (type) {
    case ACTIONS.LOGIN_START: return { ...state, loading: true };

    case ACTIONS.LOGIN_SUCCESS: return {
      ...state, user: payload.user, isAuthorised: true, loading: false,
    };

    case ACTIONS.LOGOUT: return {
      ...state,
    };

    case ACTIONS.ATHORISED: return {
      ...state, user: payload.user, isAuthorised: true, initializing: true,
    };

    case ACTIONS.UNATHORISED: return {
      ...state, isAuthorised: false, user: {}, initializing: true,
    };

    case ACTIONS.LOGIN_ERROR: return {
      ...state, errorMessage: payload,
    };

    default: return state;
  }
};

const AuthProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, context);

  useEffect(() => {
    const storeCb = () => {
      if (!sessionStore.token.getItem()) {
        dispatch({ type: ACTIONS.UNATHORISED });
      }
    };

    window.addEventListener('storage', storeCb);

    return () => window.removeEventListener('storage', storeCb);
  }, []);

  useEffect(() => {
    if (!sessionStore.token.getItem()) {
      dispatch({ type: ACTIONS.UNATHORISED });
      return;
    }

    async function getUser() {
      const { data, success } = await api.apiGet('auth/verify');

      if (success) {
        dispatch({ type: ACTIONS.ATHORISED, payload: data });
      } else {
        dispatch({ type: ACTIONS.UNATHORISED });
      }
    }
    getUser();
  }, []);

  const onLogin = useCallback(async (payload) => {
    let status;
    try {
      dispatch({ type: ACTIONS.LOADING_START });
      const { data, success, message } = await api.apiPost(payload, 'auth/login');

      if (success) {
        sessionStore.token.setItem(data.token);
        dispatch({ type: ACTIONS.LOGIN_SUCCESS, payload: data });
        status = true;
      } else {
        dispatch({ type: ACTIONS.LOGIN_ERROR, payload: message });
        status = false;
      }
    } catch (error) {
      dispatch({ type: ACTIONS.LOGIN_ERROR, payload: error });
    }
    return status;
  }, []);

  const onLogout = useCallback(() => {
    dispatch({ type: ACTIONS.LOGOUT });
    sessionStore.token.removeItem();
  }, []);

  return state.initializing
    ? (
      <authContext.Provider value={{ ...state, onLogin, onLogout }}>
        {children}
      </authContext.Provider>
    )
    : <Loader isLoading />;
};

export default AuthProvider;
