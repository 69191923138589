import { lazy } from 'react';
import * as routes from 'constants/routes';

const config = {
  path: routes.LOGIN,
  roles: [],
  isAuthorized: false,
  component: lazy(() => import('./Component')),
};

export default config;
