import { createContext } from 'react';
import { User } from 'types';
import { FUNCTION } from 'utils/always';

export const context = {
  user: {} as User,
  isAuthorised: false,
  initializing: false,
  loading: false,
  errorMessage: null,
};

export default createContext({ ...context, onLogin: FUNCTION, onLogout: FUNCTION });
