import React from 'react';
import ReactDOM from 'react-dom';
import Routing from 'routes';
import { AuthProvider } from 'components/containers';
import reportWebVitals from './reportWebVitals';
import 'styles/index.scss';

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <Routing />
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
