import React from 'react';
import { Link } from 'react-router-dom';
import * as routes from 'constants/routes';
import svgReturn from '../../../images/userElement/return.svg';
import styles from './styles.module.scss';

const BackToMenu = () => (
  <Link to={routes.MENU} className={styles.container__return}>
    <img src={svgReturn} alt="" />
    <span>Back</span>
  </Link>
);

export default BackToMenu;
