const getSessionStoreEntity = (key: string) => ({
  getItem: () => {
    const item = sessionStorage.getItem(key);
    return item ? JSON.parse(sessionStorage.getItem(key) ?? '') : null;
  },
  setItem: (value: any) => {
    sessionStorage.setItem(key, JSON.stringify(value));
    window.dispatchEvent(new Event('storage'));
  },
  removeItem: () => {
    sessionStorage.removeItem(key);
    window.dispatchEvent(new Event('storage'));
  },
});

export const token = getSessionStoreEntity('token');
