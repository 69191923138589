let tempId = 5;

export const getTempIdState = () => tempId;

export const getTempId = () => {
  tempId += 1;
  return `-${tempId}`;
};

export const LINE_TYPES = {
  header: 'HEADER',
  order_line: 'ORDER_LINE',
};

export const config = {
  orderElementsLayoutLines: {
    name: 'orderElementsLayoutLines',
  },
};

export default (name: string, _tempId = getTempId(), isOrderLine = false) => ([{
  children: null,
  parentId: null,
  position: 0,
  orderElementId: _tempId,
  name,
  lineType: isOrderLine ? LINE_TYPES.order_line : LINE_TYPES.header,
  tempId: _tempId,
  collapsed: false,
  id: _tempId,
  leaf: false,
}]);
